import { request } from 'core/request';
import _ from 'lodash';

const getBookmarks = async (searchText = '') => {
  let url = `dashboard/bookmarks/getData`;
  if (searchText) url += `?searchText=${searchText}`;
  const data = await request({
    method: 'get',
    url
  });

  return _.orderBy(data, ['order'], ['asc']);
};

const getBookmarkById = async bookmarkId => {
  const data = await request({
    method: 'get',
    url: `dashboard/bookmarks/detail/${bookmarkId}`
  });
  return data;
};

const addBookmark = async bookmark => {
  const data = await request({
    method: 'post',
    url: 'dashboard/bookmarks',
    data: bookmark
  });
  return data;
};

const updateBookmark = async (bookmarkId, bookmarkData) => {
  const data = await request({
    method: 'put',
    url: `dashboard/bookmarks/update/${bookmarkId}`,
    data: bookmarkData
  });
  return data;
};

const deleteBookmark = async bookmarkId => {
  const data = await request({
    method: 'delete',
    url: `dashboard/bookmarks/delete/${bookmarkId}`
  });
  return data;
};

const dragDropBookmark = async bookmarks => {
  const data = await request({
    method: 'put',
    url: `dashboard/bookmarks/dragDrop`,
    data: { bookmarks }
  });
  return data;
};

export default {
  addBookmark,
  getBookmarkById,
  getBookmarks,
  updateBookmark,
  deleteBookmark,
  dragDropBookmark
};
